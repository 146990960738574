import LocalScheme from '@nuxtjs/auth/lib/schemes/local';
import { STATUSES } from '~/utils/dictionaries';

export default class CustomScheme extends LocalScheme {
	// eslint-disable-next-line no-underscore-dangle
	_setToken(token) {
		if (this.options.globalToken) {
		// Set Authorization token for all axios requests
			this.$auth.ctx.app.$api.axios.setHeader(this.options.tokenName, token);
			this.$auth.ctx.app.$pagesApi.axios.setHeader(this.options.tokenName, token);
		}
	}

	// eslint-disable-next-line no-underscore-dangle
	_clearToken() {
		if (this.options.globalToken) {
		// Clear Authorization token for all axios requests
			this.$auth.ctx.app.$api.axios.setHeader(this.options.tokenName, false);
			this.$auth.ctx.app.$pagesApi.axios.setHeader(this.options.tokenName, false);
		}
	}

	mounted() {
		this.options.redirect = false;
		this.$auth.options.redirect = false;

		if (this.options.tokenRequired) {
			const token = this.$auth.syncToken(this.name);
			this._setToken(token);
		}

		return this.$auth.fetchUserOnce();
	}

	async login(endpoint) {
		if (!this.options.endpoints.login) {
			return null;
		}

		// Ditch any leftover local tokens before attempting to log in
		await this.$auth.reset();

		const { response, result: { token } } = await this.$auth.request(
			endpoint,
			this.options.endpoints.login,
			true,
		);

		if (this.options.tokenRequired) {
			const accessToken = token.access_token;
			const refreshToken = token.refresh_token;
			const tokenType = token.token_type || this.options.tokenType;

			const customToken = tokenType
				? `${tokenType} ${accessToken}`
				: accessToken;

			this.$auth.setToken(this.name, customToken);
			// eslint-disable-next-line no-underscore-dangle
			this._setToken(customToken);
			this.$auth.setRefreshToken(this.name, refreshToken);

			this.$auth.options.rewriteRedirects = this.$auth.options.redirect;
		}

		this.$auth.setUser(response.data.data);

		return response;
	}

	async fetchUser(endpoint) {
		// Token is required but not available
		if (this.options.tokenRequired && !this.$auth.getToken(this.name)) {
			return;
		}

		// User endpoint is disabled.
		if (!this.options.endpoints.user) {
			this.$auth.setUser({});
			return;
		}

		// Try to fetch user and then set
		try {
			const user = await this.$auth.requestWith(
				this.name,
				endpoint,
				this.options.endpoints.user,
			);
			this.$auth.setUser(user);
		} catch (error) {
			const { response } = error;
			if (response) {
				const { status } = response;
				if (status === STATUSES.UNAUTHORIZED) {
					this.$auth.setToken(this.name, false);
					return;
				}
			}
			throw error;
		}
	}
}
