export function state() {
	return {
		unwatchedSpecialPromotionsCount: 0,
		unwatchedAnnouncementCount: 0,
		isUserPointsChanged: false,
	};
}

export const actions = {
};

export const getters = {
	unwatchedSpecialPromotionsCount: (state) => state.unwatchedSpecialPromotionsCount,
	unwatchedAnnouncementCount: (state) => state.unwatchedAnnouncementCount,
	isUserPointsChanged: (state) => state.isUserPointsChanged,
};

export const mutations = {
	setUnwatchedSpecialPromotions(state, count) {
		state.unwatchedSpecialPromotionsCount = count;
	},
	setIsUserPointsChanged(state, value) {
		state.isUserPointsChanged = value;
	},
	setUnwatchedAnnouncements(state, count) {
		state.unwatchedAnnouncementCount = count;
	},
};
